<template>
  <div class="pay-set">
    <a-form-model
      class="pay-set-form"
      ref="paySetForm"
      label-align="left"
      layout="horizontal"
      :label-col="{span: 8}"
      :wrapper-col="{span: 16}"
      :model="form"
      :rules="rules"
      v-if="edit">
      <template v-if="single">
        <a-form-model-item label="支付时间" prop="maxPayTime">
          <a-input-number v-model="form.maxPayTime" placeholder="请输入支付时间" style="margin-right: 8px;width: 80px;"></a-input-number>
          <span>小时内支付完成，未支付则订单取消</span>
        </a-form-model-item>
        <a-form-model-item label="退款" prop="refundDisable">
          <a-checkbox v-model="form.refundDisable">不支持退款</a-checkbox>
        </a-form-model-item>
      </template>
      <template v-else>
        <a-form-model-item label="支付通道开启时间" prop="payTimeType">
          <a-radio-group v-model="form.payTimeType">
            <a-radio :value="1">预约成功后开启</a-radio>
            <a-radio :value="2">佛事日前</a-radio>
            <a-radio :value="3">立即支付</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="支付开启提前天数" prop="payTimeOffset" v-if="form.payTimeType == 2">
          <a-input-number v-model="form.payTimeOffset" placeholder="支付提前天数" style="margin-right: 8px;width: 80px;"></a-input-number>
          <span>天</span>
        </a-form-model-item>
        <a-form-model-item label="支付时间" prop="maxPayTime">
          <a-input-number v-model="form.maxPayTime" placeholder="请输入支付时间" style="margin-right: 8px;width: 80px;"></a-input-number>
          <span>小时内支付完成，未支付则订单取消</span>
        </a-form-model-item>
        <a-form-model-item label="用户未支付提醒" prop="remindTime">
          <span>支付开启后</span>
          <a-input-number v-model="form.remindTime" placeholder="请输入提醒时间" style="margin: 0 8px;width: 80px;"></a-input-number>
          <span>小时，用户未支付通知客堂管理员</span>
        </a-form-model-item>
        <template v-if="type == 1">
          <a-form-model-item label="单独普佛退款截止时间" prop="singleRefundTime">
            <span>佛事日前</span>
            <a-input-number v-model="form.singleRefundTime" placeholder="请输入退款截止时间" style="margin: 0 8px;width: 80px;"></a-input-number>
            <span>小时</span>
          </a-form-model-item>
          <a-form-model-item label="众信普佛退款截止时间" prop="groupRefundTime">
            <span>佛事日前</span>
            <a-input-number v-model="form.groupRefundTime" placeholder="请输入退款截止时间" style="margin: 0 8px;width: 80px;"></a-input-number>
            <span>小时</span>
          </a-form-model-item>
        </template>
        <a-form-model-item label="退款截止时间" prop="refundTime" v-else>
          <span>佛事日前</span>
          <a-input-number v-model="form.refundTime" placeholder="请输入退款截止时间" style="margin: 0 8px;width: 80px;"></a-input-number>
          <span>小时</span>
        </a-form-model-item>
      </template>
    </a-form-model>
    <div class="pay-set-info" v-else>
      <template v-if="single">
        <div class="detail-info-item">
          <div class="detail-info-label">支付时间</div>
          <div class="detail-info-value">{{form.maxPayTime}}小时内支付完成，未支付则订单取消</div>
        </div>
        <div class="detail-info-item">
          <div class="detail-info-label">退款</div>
          <div class="detail-info-value">{{form.refundDisable ? '不支持退款' : '支持退款'}}</div>
        </div>
      </template>
      <template v-else>
        <div class="detail-info-item">
          <div class="detail-info-label">支付通道开启时间</div>
          <div class="detail-info-value">{{form.payTimeType == 2 ? `佛事日前${form.payTimeOffset}天` : (form.payTimeType == 1 ? '预约成功后开启' : '立即支付')}}</div>
        </div>
        <div class="detail-info-item">
          <div class="detail-info-label">支付时间</div>
          <div class="detail-info-value">{{form.maxPayTime}}小时内支付完成，未支付则订单取消</div>
        </div>
        <div class="detail-info-item">
          <div class="detail-info-label">用户未支付提醒</div>
          <div class="detail-info-value">支付开启后{{form.remindTime}}小时，用户未支付通知客堂管理员</div>
        </div>
        <template v-if="type == 1">
          <div class="detail-info-item">
            <div class="detail-info-label">单独普佛退款截止时间</div>
            <div class="detail-info-value">佛事日前{{form.singleRefundTime}}小时</div>
          </div>
          <div class="detail-info-item">
            <div class="detail-info-label">众信普佛退款截止时间</div>
            <div class="detail-info-value">佛事日前{{form.groupRefundTime}}小时</div>
          </div>
        </template>
        <div class="detail-info-item" v-else>
          <div class="detail-info-label">退款截止时间</div>
          <div class="detail-info-value">佛事日前{{form.refundTime}}小时</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

  export default {
        name: "pay-set",
        model: {
            event: "change"
        },
        props: {
            //是否可编辑
            edit: {
                type: Boolean,
                default: true
            },
            value: Object,
            type: Number,// 佛事类别
            single: Boolean // 是否是简单配置
        },
        data() {
            return {
                form: {
                    payTimeType: 2,
                    payTimeOffset: 72,
                    maxPayTime: 48,
                    remindTime: 24,
                    refundTime: 48
                },
                rules: {
                    payTimeType: [{ required: true, message: '请选择支付通道开启时间', trigger: 'change' }],
                    payTimeOffset: [{ required: true, message: '请输入天数', trigger: 'blur' }],
                    maxPayTime: [{ required: true, message: '请输入支付时间', trigger: 'blur' }],
                    remindTime: [{ required: true, message: '请输入提醒时间', trigger: 'blur' }],
                    refundTime: [{ required: true, message: '请输入退款截止时间', trigger: 'blur' }],
                    singleRefundTime: [{ required: true, message: '请输入退款截止时间', trigger: 'blur' }],
                    groupRefundTime: [{ required: true, message: '请输入退款截止时间', trigger: 'blur' }],
                }
            }
        },
        watch: {
            value() {
                this.setForm();
            }
        },
        created() {
            this.setForm();
        },
        methods: {
            setForm() {
                let value = this.value;
                if(value) {
                    this.form = {...value};
                } else {
                    if(this.single) {
                        this.form = {
                            maxPayTime: 24
                        }
                    } else {
                        const form = {
                            payTimeType: 2,
                            payTimeOffset: 72,
                            maxPayTime: 48,
                            remindTime: 24,
                        };
                        if(this.type == 1) {
                            form.singleRefundTime = 48;
                            form.groupRefundTime = 48;
                        } else {
                            form.refundTime = 48;
                        }
                        this.form = form;
                    }
                }
            },
            checkForm() {
                return new Promise((resolve, reject) => {
                    this.$refs.paySetForm.validate(valid => {
                        if (valid) {
                            resolve(this.getValue());
                        } else {
                            reject("form error");
                        }
                    });
                })
            },
            getValue() {
                let form = this.form;
                let res;
                if(this.single) {
                    res = {
                        maxPayTime: form.maxPayTime
                    }
                    if(form.refundDisable) {
                        res.refundDisable = true;
                    }
                } else {
                    res = {
                        payTimeType: form.payTimeType,
                        maxPayTime: form.maxPayTime,
                        remindTime: form.remindTime,
                    }
                    if(this.type == 1) {
                        res.singleRefundTime = form.singleRefundTime;
                        res.groupRefundTime = form.groupRefundTime;
                    } else {
                        res.refundTime = form.refundTime;
                    }
                    if(form.payTimeType == 2) {
                        res.payTimeOffset = form.payTimeOffset;
                    }
                }
                return res;
            }
        }
    }
</script>

<style lang="less">
  .pay-set-info {
    .detail-info-item {
      font-size: 14px;
    }
  }
</style>
