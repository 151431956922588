<template>
  <div class="tablet-print-area">
    <div class="buddha-tablet-paper" style="display:flex;justify-content:center;width:1123px;height:793px;background-color: #fff;page-break-after: always;overflow: hidden;" v-for="(l, n) in arr" :key="n">
      <tablet :tablet="t" :temp="t.temp" style="display:flex;flex-direction: column;align-items:center;justify-content:center;width:25%;" :width="260" v-for="t in l" :key="t.id"></tablet>
    </div>
  </div>
</template>

<script>
    import Tablet from "./Tablet";
    export default {
        name: "tablet-print",
        components: {Tablet},
        props: {
            list: Array
        },
        computed: {
            arr() {
                let res = [];
                let list = [...(this.list || [])];
                if(list && list.length > 0) {
                    let num = 4;
                    do {
                        res.push(list.splice(0, num));
                    } while (list.length > 0)
                }
                return res;
            }
        }
    }
</script>

<style>

</style>
