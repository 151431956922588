<template>
  <div class="buddha-price-set">
    <template v-if="config && config.category == 1">
      <div class="buddha-price-item" v-if="edit && subTypeList">
        <div class="buddha-price-label">价格配置</div>
        <div class="buddha-price-ctx price-batch-set">
          <a-select class="batch-set-item" v-model="obj" :options="objectList" placeholder="对象"/>
          <a-select class="batch-set-item flex-grow" v-model="subType" :options="[{key: 0, title: '全部类型'}].concat(subTypeList)" placeholder="类型"/>
          <a-select class="batch-set-item" v-model="time"  placeholder="时间段">
            <a-select-option :value="0">全部时间</a-select-option>
            <a-select-option v-for="time in timeRangeList || []" :key="time.id">{{ time.name }}</a-select-option>
          </a-select>
          <a-input-number v-model="batchPrice" :min="0.01" placeholder="价格" class="batch-set-input"></a-input-number>
          <a-button type="primary" @click="batchSet">批量设置</a-button>
        </div>
      </div>
      <div class="buddha-price-item price-item-vertical" v-if="subTypeList">
        <div class="buddha-price-ctx">
          <table class="buddha-price-table">
            <thead>
            <tr class="price-table-tr price-table-title">
              <th class="price-table-td"></th>
              <th class="price-table-td" v-for="time in timeRangeList" :key="time.id">{{time.name}}</th>
            </tr>
            </thead>
            <tbody>
            <tr class="price-table-tr" v-for="(tr, i) in priceTable" :key="i">
              <td class="price-table-td price-table-label">{{tr.name}}</td>
              <td class="price-table-td" v-for="time in tr.list" :key="`${i}_${time.id}`">
                <a-input-number v-model="time.price" :min="0.01" placeholder="价格" class="price-table-input" v-if="edit"></a-input-number>
                <span v-else>{{time.price}}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="buddha-price-item" v-if="subTypeList">
        <div class="buddha-price-label">增加牌位</div>
        <div class="flex-box align-center buddha-price-ctx" v-if="edit">
          <a-checkbox v-model="diff">另加牌位价格与基础价格不同<span v-if="diff">，每增加一个牌位</span></a-checkbox>
          <div class="flex-box align-center" style="margin-left: 10px" v-if="diff">
            <a-input-number v-model="extra" :min="0.01" placeholder="另加牌位价格" style="margin-right: 8px;width: 120px" v-if="edit"/>
            <span v-else>{{extra}}</span>
            <span>元/个</span>
          </div>
        </div>
        <div class="buddha-price-ctx buddha-price-text" v-else>每增加一个牌位：{{diff ? `${extra}元/个` : '同基础价格'}}</div>
      </div>
      <div class="buddha-price-item" v-if="config.key == 7 || config.key == 8">
        <div class="buddha-price-label">{{config.title}}价格</div>
        <div class="buddha-price-ctx buddha-price-text">
          <a-input-number v-model="price" :min="0.01" placeholder="请输入价格" style="margin-right: 8px;width: 120px" v-if="edit"/>
          <span v-else>{{price}}</span>
          <span>元</span>
        </div>
      </div>
      <div class="buddha-price-item" v-if="config.key == 2 || config.key == 3">
        <div class="buddha-price-label">增加法师</div>
        <div class="buddha-price-ctx buddha-price-text">
          <span>每增加一个法师</span>
          <a-input-number v-model="masterPrice" :min="0.01" placeholder="请输入价格" style="margin: 0 8px;width: 120px" v-if="edit"/>
          <span v-else>{{masterPrice}}</span>
          <span>元</span>
        </div>
      </div>
    </template>
    <template v-else-if="config && config.category == 3">
      <div class="buddha-price-item" v-for="t in subTypeList" :key="t.key">
        <div class="buddha-price-label">{{t.title}}</div>
        <div class="buddha-price-ctx buddha-price-text">
          <a-input-number v-model="pujaPrice[t.key]" :min="0.01" placeholder="请输入价格" style="margin: 0 8px;width: 120px" v-if="edit"/>
          <span v-else>{{pujaPrice[t.key]}}</span>
          <span>元</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
    import {hasRelation, buddhismObjectTypeList} from "../../common/buddha/buddhism";
    import {getBuddhaTypeConfig} from "../../common/buddha/type";
    import {clone} from "../../common/js/tool";
    export default{
        name: "price-set",
        props: {
            // 佛事类型
            type: Number,
            //是否可编辑
            edit: {
                type: Boolean,
                default: true
            },
            value: Object
        },
        data() {
            return {
                objectList: [{key: 0, title: '全部对象'},...buddhismObjectTypeList],
                obj: 0,
                subType: 0,
                time: 0,
                pujaPrice: {}, // 法会价格
                priceTable: [], // 时间段价格配置数组
                batchPrice: null,
                masterPrice: null,
                price: null,
                diff: true,
                extra: null,

            }
        },
        computed: {
            timeRangeList() {
                return this.$store.getters.timeRange;
            },
            config() {
                return getBuddhaTypeConfig(this.type);
            },
            subTypeList() {
                const config = this.config;
                let res = null;
                if(config && config.subTypes) {
                    res = [...config.subTypes];
                }
                return res;
            },
        },
        watch: {
            subTypeList() {
                this.setPriceTable();
            },
            timeRangeList() {
                this.setPriceTable();
            },
            value() {
                this.setValue();
            }
        },
        created() {
            this.setPriceTable();
            this.setValue()
        },
        methods: {
            batchSet() {
                if(this.batchPrice) {
                    let {obj, subType, time} = this;
                    this.priceTable.forEach(item => {
                        if(subType == 0 || subType == item.type) {
                            if(obj == 0 || obj == item.objectType) {
                                item.list.forEach(r => {
                                    if(time == 0 || r.id == time) {
                                        this.$set(r, "price", this.batchPrice);
                                    }
                                });
                            }
                        }
                    })
                } else {
                    this.$message.warning("请输入价格");
                }
            },
            getPriceConfig() {
                let res = {};
                if(this.subTypeList) {
                    if(this.config.category == 1) {
                        let error = null;
                        const priceTable = this.priceTable;
                        const timeRangeList = this.timeRangeList;
                        let priceConfig = {};
                        for(let i = 0, l = priceTable.length; i < l; i++) {
                            let item = priceTable[i];
                            let price = {};
                            if(priceConfig[item.type]) {
                                price = priceConfig[item.type];
                            } else {
                                timeRangeList.forEach(range => {
                                    price[range.id] = [];
                                });
                                priceConfig[item.type] = price;
                            }
                            for(let j = 0, len = item.list.length; j < len; j ++) {
                                let r = item.list[j];
                                if(r.price) {
                                    if(item.objectType) {
                                        price[r.id][item.objectType - 1] = r.price
                                    } else {
                                        price[r.id][0] = r.price
                                    }
                                } else {
                                    error = `请设置${item.name}${timeRangeList[j].name}价格`;
                                    break;
                                }
                            }
                            if(error) {
                                break;
                            }
                        }
                        if(error) {
                            return  {error}
                        } else {
                            res.timeRangePrice = priceConfig;
                            res.timeRangeList = timeRangeList;
                        }
                    } else if(this.config.category == 3) {
                        let error = null;
                        let subTypeList = this.subTypeList;
                        for(let i = 0, l = subTypeList.length; i < l; i ++) {
                            let type = subTypeList[i].key;
                            if(!this.pujaPrice[type]) {
                                error = `请设置${subTypeList[i].title}价格`;
                                break;
                            }
                        }
                        if(error) {
                            return  {error}
                        } else {
                            res.pujaPrice = {...this.pujaPrice};
                        }
                    }
                }
                if (this.config) {
                    const config = this.config;
                    if(config.category == 1 && this.subTypeList) {
                        res.diff = this.diff;
                        if(res.diff) {
                            if(this.extra) {
                                res.extra = this.extra;
                            } else {
                                return {error: "请输入另加牌位价格"};
                            }
                        }
                    }
                    if(config.key == 7 || config.key == 8) {
                        if(this.price) {
                            res.price = this.price
                        } else {
                            return {error: `请输入${config.title}价格`};
                        }
                    }
                    if(config.key == 2 || config.key == 3) {
                        if(this.masterPrice) {
                            res.masterPrice = this.masterPrice
                        } else {
                            return {error: "请输入多出法师的单价"};
                        }
                    }
                }
                return res;
            },
            setValue() {
                let value = this.value;
                if(value) {
                    for(let key in value) {
                        if(key == "timeRangePrice") {
                            this.setPriceTable();
                        } else if(key != "timeRangeList"){
                            if(typeof value[key] === "object") {
                                this[key] = clone(value[key]);
                            } else {
                                this[key] = value[key];
                            }
                        }
                    }
                }
            },
            setPriceTable() {
                if(this.subTypeList && this.config.category == 1 && this.timeRangeList) {
                    let table = [];
                    let timePrice = this.value ? this.value.timeRangePrice : null;
                    let list = this.timeRangeList;
                    this.subTypeList.forEach(t => {
                        const relation = hasRelation({
                            cat: this.config.category,
                            type: t.key
                        });
                        const priceList = timePrice ? timePrice[t.key] : null;
                        const temp = list.map(range => {
                            const conf = priceList ? priceList[range.id] : null;
                            return {
                                ...range,
                                base: conf || []
                            }
                        })
                        if(relation) {
                            table.push({
                                name: t.title,
                                type: t.key,
                                list: temp.map(range => {
                                    return {
                                        id: range.id,
                                        price: range.base[0]
                                    }
                                })
                            });
                        } else {
                            table.push(...buddhismObjectTypeList.map((o, i) => {
                                return {
                                    name: `${t.title}-${o.title}`,
                                    type: t.key,
                                    objectType: o.key,
                                    list: temp.map(range => {
                                        return {
                                            id: range.id,
                                            price: range.base[i]
                                        }
                                    })
                                }
                            }));
                        }
                    });
                    this.priceTable = table;
                } else {
                    this.priceTable = [];
                }
            },
        }
    }
</script>

<style lang="less">
  .price-batch-set {
    flex: 1;
    display: flex;
    align-items: center;
  }
  .batch-set-item {
    margin-right: 8px;
    width: 100px;
  }
  .batch-set-input {
    width: 100px;
    margin-right: 8px;
  }
  .buddha-price-item {
    display: flex;
    margin-top: 10px;
    &.price-item-vertical {
      flex-direction: column;
      .buddha-price-label {
        width: 100%;
      }
    }
  }
  .buddha-price-label {
    width: 100px;
    color: @text-color-secondary;
    line-height: 32px;
  }
  .buddha-price-table {
    text-align: center;
  }
  .price-table-td {
    padding: 6px 0;
    min-width: 120px;
  }
  .price-table-label {
    text-align: left;
  }
  .buddha-price-text {
    line-height: 32px;
  }
</style>
