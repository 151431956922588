<template>
  <div class="buddha-time" v-if="temp">
    <div class="buddha-time-box">
      <h3 class="buddha-time-title">通用<span v-if="temp.fs_type != 2">日期</span>设置</h3>
      <div class="flex-box buddha-time-detail" v-if="normal">
        <template v-if="temp.fs_type == 1">
          <div class="flex-grow time-detail-piece">
            <h4 class="time-piece-title">单独普佛</h4>
            <div class="time-piece-conf">
              <div class="detail-info-item">
                <div class="detail-info-label">线上登记开启/关闭</div>
                <div class="detail-info-value">{{normal.singleOpen ? '开启' : '关闭'}}</div>
              </div>
              <div class="detail-info-item">
                <div class="detail-info-label">开放预约时间</div>
                <div class="detail-info-value">{{getTimeTypeText(normal.singleTimeType)}}</div>
              </div>
              <div class="detail-info-item">
                <div class="detail-info-label">提前预约时间</div>
                <div class="detail-info-value">佛事日前{{normal.singleAdvance[0]}}-{{normal.singleAdvance[1]}}天</div>
              </div>
              <div class="detail-info-item vertical">
                <div class="detail-info-label">单独延生普佛可预约时间段</div>
                <div class="detail-info-value">
                  <span v-if="normal.singleTypes.indexOf('singleLive') == -1">不开放预约</span>
                  <reserve-time :value="normal.singleLiveRange" :edit="false" v-else></reserve-time>
                </div>
              </div>
              <div class="detail-info-item vertical">
                <div class="detail-info-label">单独往生普佛可预约时间段</div>
                <div class="detail-info-value">
                  <span v-if="normal.singleTypes.indexOf('singleDie') == -1">不开放预约</span>
                  <reserve-time :value="normal.singleDieRange" :edit="false" v-else></reserve-time>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-grow time-detail-piece" style="margin-left: 20px">
            <h4 class="time-piece-title">众信普佛</h4>
            <div class="time-piece-conf">
              <div class="detail-info-item">
                <div class="detail-info-label">线上登记开启/关闭</div>
                <div class="detail-info-value">{{normal.groupOpen ? '开启' : '关闭'}}</div>
              </div>
              <div class="detail-info-item">
                <div class="detail-info-label">开放预约时间</div>
                <div class="detail-info-value">{{getTimeTypeText(normal.groupTimeType)}}（日期内{{normal.coexist ? '可' : '不可'}}预约单独普佛）</div>
              </div>
              <div class="detail-info-item">
                <div class="detail-info-label">佛事预约时间</div>
                <div class="detail-info-value">{{normal.groupDateRange[0]}}至{{normal.groupDateRange[1]}}</div>
              </div>
              <div class="detail-info-item vertical">
                <div class="detail-info-label">众信延生普佛可预约时间段</div>
                <div class="detail-info-value">
                  <span v-if="normal.groupTypes.indexOf('groupLive') == -1">不开放预约</span>
                  <reserve-time :value="normal.groupLiveRange" :edit="false" v-else></reserve-time>
                </div>
              </div>
              <div class="detail-info-item vertical">
                <div class="detail-info-label">单独往生普佛可预约时间段</div>
                <div class="detail-info-value">
                  <span v-if="normal.groupTypes.indexOf('groupDie') == -1">不开放预约</span>
                  <reserve-time :value="normal.groupDieRange" :edit="false" v-else></reserve-time>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="flex-grow time-detail-piece" v-else>
          <div class="time-piece-conf">
            <div class="detail-info-item">
              <div class="detail-info-label">开放预约时间</div>
              <div class="detail-info-value">{{getTimeTypeText(normal.timeType)}}</div>
            </div>
            <div class="detail-info-item">
              <div class="detail-info-label">提前预约时间</div>
              <div class="detail-info-value">佛事日前{{normal.advance[0]}}-{{normal.advance[1]}}天</div>
            </div>
            <div class="detail-info-item vertical">
              <div class="detail-info-label">可预约时间段</div>
              <div class="detail-info-value">
                <reserve-time :value="normal.timeRange" :edit="false"></reserve-time>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div><span v-if="!normal">未设置，点击</span> <span class="txt-btn" @click="showEdit('normal')">设置</span></div>
    </div>
    <div class="gap"></div>
    <div class="buddha-time-box">
      <h3 class="buddha-time-title">特殊日期设置</h3>
      <div class="flex-box" style="border: var(--border)">
        <div class="flex-grow time-select-piece">
          <calendar
            style="padding: 10px"
            :month-style="{padding: '6px'}"
            :week-style="{height: '32px', lineHeight: '32px'}"
            lunar
            @month="val => current = val">
            <template v-slot:full="{day}">
              <div class="buddha-time-day" :class="{'time-day-select': select === day.dateStr, 'time-day-special': special[day.dateStr]}" @click.stop.prevent="handleSelect($event, day)">
                <div class="time-day-num">{{day.day}}</div>
                <div class="time-day-lunar">{{day.lunar.lnongDate}}</div>
              </div>
            </template>
          </calendar>
        </div>
        <div class="time-select-piece buddha-time-set">
          <div class="time-day-set" v-if="select">
            <h4 class="time-piece-title">
              {{select}}
              <span class="txt-btn" @click="showEdit('special')">设置</span>
            </h4>
            <div class="gap"></div>
            <template v-if="special[select]">
              <div class="detail-info-item" v-if="special[select].disable">
                <div class="detail-info-label">预约配置</div>
                <div class="detail-info-value">不开放预约</div>
              </div>
              <template v-else>
                <template v-if="temp.fs_type == 1">
                  <div class="day-set-detail" v-for="t in subTypeList" :key="t.key">
                    <h4 class="time-piece-title">{{t.title}}</h4>
                    <div class="detail-info-item">
                      <div class="detail-info-label">预约配置</div>
                      <div class="detail-info-value">
                        <span v-if="special[select].subTypes.indexOf(t.key) == -1">不开放预约</span>
                        <reserve-time :value="special[select][`${t.key}Range`]" :edit="false" v-else></reserve-time>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="detail-info-item" v-else>
                  <div class="detail-info-label">预约配置</div>
                  <div class="detail-info-value">
                    <reserve-time :value="special[select].timeRange" :edit="false"></reserve-time>
                  </div>
                </div>
              </template>
            </template>
            <div class="day-set-detail" v-else>
              <div class="detail-info-item">
                <div class="detail-info-label">预约配置</div>
                <div class="detail-info-value">通用日期配置</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      :title="title"
      :maskClosable="false"
      :closable="false"
      :destroyOnClose="true"
      :width="840"
      @ok="confirmTimeRange"
      v-model="visible">
      <a-form-model
        class="pay-set-form"
        ref="timeForm"
        label-align="left"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 18}"
        :model="form"
        :rules="rules">
        <template v-if="type == 'normal'">
          <template v-if="temp.fs_type == 1">
            <h4 class="time-piece-title">单独普佛</h4>
            <a-form-model-item label="开放日期" prop="singleTimeType">
              <a-radio-group v-model="form.singleTimeType">
                <a-radio :value="1">每天</a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="线上登记开启/关闭" prop="singleOpen">
              <a-switch checked-children="开启" un-checked-children="关闭" v-model="form.singleOpen" />
            </a-form-model-item>
            <a-form-model-item label="提前预约时间区间" prop="singleAdvance">
              <number-range suffix="天" v-model="form.singleAdvance"></number-range>
            </a-form-model-item>
            <a-form-model-item label="可选佛事种类" prop="singleTypes">
              <a-checkbox-group :options="[{value: 'singleLive', label: '单独延生普佛'}, {value: 'singleDie', label: '单独往生普佛'}]" v-model="form.singleTypes" />
            </a-form-model-item>
            <a-form-model-item label="单独延生普佛可预约时间段" prop="singleLiveRange" v-if="form.singleTypes && form.singleTypes.indexOf('singleLive') >= 0">
              <reserve-time v-model="form.singleLiveRange"></reserve-time>
            </a-form-model-item>
            <a-form-model-item label="单独往生普佛可预约时间段" prop="singleDieRange" v-if="form.singleTypes && form.singleTypes.indexOf('singleDie') >= 0">
              <reserve-time v-model="form.singleDieRange"></reserve-time>
            </a-form-model-item>
            <div class="gap"></div>
            <h4 class="time-piece-title">众信普佛</h4>
            <a-form-model-item label="线上登记开启/关闭" prop="groupOpen">
              <a-switch checked-children="开启" un-checked-children="关闭" v-model="form.groupOpen" />
            </a-form-model-item>
            <a-form-model-item label="开放日期" prop="groupTimeType">
              <a-checkbox-group :options="groupTimeList" v-model="form.groupTimeType"></a-checkbox-group>
            </a-form-model-item>
            <a-form-model-item label="开放预约时间段" prop="groupDateRange">
              <a-range-picker
                v-model="form.groupDateRange"
                :placeholder="['起始时间', '结束时间']"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"/>
            </a-form-model-item>
            <a-form-model-item label="是否可预约单独普佛" prop="coexist">
              <a-checkbox v-model="form.coexist">众信普佛可预约日期是否可预约单独普佛</a-checkbox>
            </a-form-model-item>
            <a-form-model-item label="可选佛事种类" prop="singleTypes">
              <a-checkbox-group :options="[{value: 'groupLive', label: '众信延生普佛'}, {value: 'groupDie', label: '众信往生普佛'}]" v-model="form.groupTypes" />
            </a-form-model-item>
            <a-form-model-item label="众信延生普佛可预约时间段" prop="groupLiveRange" v-if="form.groupTypes && form.groupTypes.indexOf('groupLive') >= 0">
              <reserve-time v-model="form.groupLiveRange"></reserve-time>
            </a-form-model-item>
            <a-form-model-item label="众信往生普佛可预约日期" prop="groupDieRange" v-if="form.groupTypes && form.groupTypes.indexOf('groupDie') >= 0">
              <reserve-time v-model="form.groupDieRange"></reserve-time>
            </a-form-model-item>
          </template>
          <template v-else>
            <a-form-model-item label="开放日期" prop="timeType">
              <a-radio-group v-model="form.timeType">
                <a-radio :value="1">每天</a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="提前预约时间区间" prop="advance">
              <number-range suffix="天" v-model="form.advance"></number-range>
            </a-form-model-item>
            <a-form-model-item label="可预约时间段" prop="timeRange">
              <reserve-time v-model="form.timeRange"></reserve-time>
            </a-form-model-item>
          </template>
        </template>
        <template v-else>
          <a-form-model-item label="预约配置" prop="configType">
            <a-radio-group v-model="form.configType">
              <a-radio :value="1">通用日期配置</a-radio>
              <a-radio :value="2">自定义</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <template v-if="form.configType == 2">
            <a-form-model-item label="当日不开放预约" prop="disable">
              <a-checkbox v-model="form.disable"></a-checkbox>
            </a-form-model-item>
            <template v-if="!form.disable">
              <template v-if="temp.fs_type == 1">
                <a-form-model-item label="可选佛事种类" prop="subTypes">
                  <a-checkbox-group
                    :options="[{value: 'singleLive', label: '单独延生普佛'}, {value: 'singleDie', label: '单独往生普佛'},{value: 'groupLive', label: '众信延生普佛'}, {value: 'groupDie', label: '众信往生普佛'}]"
                    v-model="form.subTypes" />
                </a-form-model-item>
                <a-form-model-item label="单独延生普佛可预约时间段" prop="singleLiveRange" v-if="form.subTypes && form.subTypes.indexOf('singleLive') >= 0">
                  <reserve-time v-model="form.singleLiveRange"></reserve-time>
                </a-form-model-item>
                <a-form-model-item label="单独往生普佛可预约时间段" prop="singleDieRange" v-if="form.subTypes && form.subTypes.indexOf('singleDie') >= 0">
                  <reserve-time v-model="form.singleDieRange"></reserve-time>
                </a-form-model-item>
                <a-form-model-item label="众信延生普佛可预约时间段" prop="groupLiveRange" v-if="form.subTypes && form.subTypes.indexOf('groupLive') >= 0">
                  <reserve-time v-model="form.groupLiveRange"></reserve-time>
                </a-form-model-item>
                <a-form-model-item label="众信往生普佛可预约时间段" prop="groupDieRange" v-if="form.subTypes && form.subTypes.indexOf('groupDie') >= 0">
                  <reserve-time v-model="form.groupDieRange"></reserve-time>
                </a-form-model-item>
              </template>
              <a-form-model-item label="可预约时间段" prop="timeRange" v-else>
                <reserve-time v-model="form.timeRange"></reserve-time>
              </a-form-model-item>
            </template>
          </template>
        </template>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
    import calendar from "../calendar/calendar";
    import {clone, isArray} from "../../common/js/tool";
    import reserveTime from "../../components/buddha/reserve-time";
    import {getBuddhaNormalTimeConfig, getBuddhaSpecialTimeConfig} from "../../common/buddha/type";

    export default{
        name: "buddha-time",
        components: {
            calendar,
            reserveTime
        },
        model: {
            event: "change"
        },
        props: {
            //是否可编辑
            edit: {
                type: Boolean,
                default: true
            },
            // fs_temp实体
            temp: Object,
        },
        data() {
            return {
                select: null, //选中的日期
                type: null, //当前设置的是通用还是特殊日期 normal 通用 special 特殊
                normal: null, //通用日期配置
                special: {}, //特殊日期配置
                current: null, //日历当前月份 Date类型
                form: {},
                rules: {
                    timeType: [{ required: true, message: '请选择单开放日期', trigger: 'change' }],
                    timeRange: [{ required: true, message: '请设置可预约时间段', trigger: 'change' }],
                    advance: [{ required: true, message: '请设置提前预约时间区间', trigger: 'change' }],
                    singleOpen: [{ required: true, message: '请选择单独普佛开启/关闭状态', trigger: 'change' }],
                    singleTimeType: [{ required: true, message: '请选择单独普佛开放日期', trigger: 'change' }],
                    singleAdvance: [{ required: true, message: '请设置单独普佛提前预约时间区间', trigger: 'change' }],
                    singleTypes: [{ required: true, message: '请选择可选佛事种类', trigger: 'change' }],
                    singleLiveRange: [{ required: true, message: '请设置单独延生普佛可预约时间段', trigger: 'change' }],
                    singleDieRange: [{ required: true, message: '请设置单独往生普佛可预约时间段', trigger: 'change' }],
                    groupOpen: [{ required: true, message: '请选择众信普佛开启/关闭状态', trigger: 'change' }],
                    groupTimeType: [{ required: true, message: '请选择众信普佛开放日期', trigger: 'change' }],
                    groupDateRange: [{ required: true, message: '请选择众信普佛开放预约时间段', trigger: 'change' }],
                    groupTypes: [{ required: true, message: '请选择可选佛事种类', trigger: 'change' }],
                    groupLiveRange: [{ required: true, message: '请设置众信延生普佛可预约时间段', trigger: 'change' }],
                    groupDieRange: [{ required: true, message: '请设置众信往生普佛可预约时间段', trigger: 'change' }],
                    configType: [{ required: true, message: '请选择日期配置类型', trigger: 'change' }],
                    subTypes: [{ required: true, message: '请选择可选佛事种类', trigger: 'change' }],
                },
                visible: false,
                subTypeList: [
                    {key: 'singleLive', title: '单独延生普佛'},
                    {key: 'singleDie', title: '单独往生普佛'},
                    {key: 'groupLive', title: '众信延生普佛'},
                    {key: 'groupDie', title: '众信往生普佛'},
                ],
                groupTimeList: [
                    {label: '初一', value: 1},
                    {label: '十五', value: 2},
                    {label: '佛诞日', value: 3},
                ],
                loading: false
            }
        },
        computed: {
            title() {
                let type = this.type, select = this.select;
                return `${type == 'normal' ? '通用日期' : select}预约设置`;
            },
            // 最新的时间段
            timeRangeList() {
                return this.$store.getters.timeRange;
            },
        },
        watch: {
            temp() {
                this.init();
            },
            current() {
                this.getSpecialTime();
            },
        },
        created() {
            this.init();
        },
        methods: {
            init() {
                if(this.temp) {
                    this.getNormalTime();
                }
            },
            delSpecial() {
                this.$confirm({
                    title: '提升',
                    content: "确定删除此日期的预约配置吗？",
                    onOk: () => {
                        let item = this.special[this.select];
                        this.loading = true;
                        this.$axios({
                            url: `/admin/fs-temp-reserve/${item.id}`,
                            method: 'DELETE'
                        }).then(() => {
                            this.visible = false;
                            this.$set(this.special, this.select, null);
                        }).finally(() => {
                            this.loading = false;
                        });
                    }
                })
            },
            showEdit(type) {
                this.type = type;
                const fsType = this.temp.fs_type;
                if(type == 'normal') {
                    const normal = this.normal;
                    switch (fsType) {
                        case 1:
                            if(normal) {
                                this.form = {
                                    coexist: normal.coexist,
                                    singleOpen: !!normal.singleOpen,
                                    singleTimeType: normal.singleTimeType,
                                    singleAdvance: [...(normal.singleAdvance || [])],
                                    singleTypes: [...(normal.singleTypes || [])],
                                    singleLiveRange: [...(normal.singleLiveRange || [])],
                                    singleDieRange: [...(normal.singleDieRange || [])],
                                    groupOpen: !!normal.groupOpen,
                                    groupTimeType: [...(normal.groupTimeType || [])],
                                    groupDateRange: [...(normal.groupDateRange || [])],
                                    groupTypes: [...(normal.groupTypes || [])],
                                    groupLiveRange: [...(normal.groupLiveRange || [])],
                                    groupDieRange: [...(normal.groupDieRange || [])],
                                };
                            } else {
                                this.form = {
                                    singleOpen: true,
                                    groupOpen: true,
                                    singleTimeType: 1,
                                    groupTimeType: [1, 2],
                                    singleAdvance: [3, 45],
                                    singleTypes: ['singleLive', 'singleDie'],
                                    groupTypes: ['groupLive', 'groupDie']
                                }
                            }
                            break;
                        case 2:
                            if(normal) {
                                this.form = {
                                    timeRange: normal.timeRange,
                                    advance: [...(normal.advance || [])],
                                }
                            } else {
                                this.form = {advance: [3, 45]};
                            }
                            break;
                        case 3:
                            if(normal) {
                                this.form = {
                                    timeType: normal.timeType,
                                    timeRange: normal.timeRange,
                                    advance: [...(normal.advance || [])]
                                }
                            } else {
                                this.form = {timeType: 1, advance: [3, 45]};
                            }
                            break;
                    }
                } else {
                    if(this.special[this.select]) {
                        this.form = {...clone(this.special[this.select]), configType: 2};
                    } else {
                        let form = {
                            configType: 1
                        };
                        if(this.normal) {
                            if(this.temp.fs_type == 1) {
                                form.subTypes = clone(this.normal.singleTypes.concat(this.normal.groupTypes));
                                form.singleLiveRange = clone(this.normal.singleLiveRange);
                                form.singleDieRange = clone(this.normal.singleDieRange);
                                form.groupLiveRange = clone(this.normal.groupLiveRange);
                                form.groupDieRange = clone(this.normal.groupDieRange);
                            } else {
                                form.timeRange = clone(this.normal.timeRange);
                            }
                        }
                        this.form = form;
                    }
                }
                this.visible = true;
            },
            getTimeTypeText(type) {
                if(isArray(type)) {
                    return type.map(t => {
                        const item = this.groupTimeList.find(time => time.value == t);
                        return item ? item.label : ''
                    }).join(" ");
                } else {
                    return ['每天', '农历初一、十五'][type - 1]
                }
            },
            getNormalTime() {
                getBuddhaNormalTimeConfig(this.temp).then(normal => {
                    this.normal = normal;
                })
            },
            getSpecialTime() {
                getBuddhaSpecialTimeConfig(this.temp, this.current).then(special => {
                    this.special = special;
                });
            },
            confirmTimeRange() {
                if(this.loading) return;
                this.$refs.timeForm.validate().then(valid => {
                    if(valid) {
                        let form = this.form;
                        let type = this.type;
                        if(form.advance && typeof form.advance === "string") {
                            form.advance = form.advance.split(",");
                        }
                        if(form.singleAdvance &&  typeof form.singleAdvance === "string") {
                            form.singleAdvance = form.singleAdvance.split(",");
                        }
                        if(type == 'normal') {
                            this.updateNormal(form);
                        } else {
                            this.updateSpecial(form);
                        }
                    }
                });
            },
            updateNormal(form) {
                this.loading = true;
                let normal = this.normal;
                let url = `/admin/fs-temp-reserve`;
                let method = "POST";
                form.timeRangeList = this.timeRangeList;
                let data = {
                    config: JSON.stringify(form)
                }
                if(normal) {
                    url += `/${normal.id}`;
                    method = "PATCH";
                } else {
                    data.temp_id = this.temp.id;
                    data.is_common = 1;
                }
                this.$axios({
                    url,
                    method,
                    data
                }).then(res => {
                    this.visible = false;
                    this.normal = {...res, ...form};
                }).finally(() => {
                    this.loading = false;
                });
            },
            updateSpecial(form) {
                let item = this.special[this.select];
                if(form.configType == 2) {
                    this.loading = true;
                    let conf = {};
                    if(form.disable) {
                        conf.disable = true;
                    } else {
                        delete form.configType;
                        delete form.disable;
                        conf = {...conf, ...form}
                    }
                    let url = `/admin/fs-temp-reserve`;
                    let method = "POST"
                    let data = {
                        config: JSON.stringify(conf)
                    }
                    if(item) {
                        url += `/${item.id}`;
                        method = "PATCH";
                    } else {
                        data.temp_id = this.temp.id;
                        data.is_common = 0;
                        data.date = this.select;
                    }
                    this.$axios({
                        url,
                        method,
                        data
                    }).then(res => {
                        this.visible = false;
                        this.$set(this.special, this.select, {...res, ...conf})
                    }).finally(() => {
                        this.loading = false;
                    });
                } else if(item && form.configType == 1) {
                    // 此日期存在特殊设置 且改回通用配置 删除特殊日期配置
                    this.loading = true;
                    this.$axios({
                        url: `/admin/fs-temp-reserve/${item.id}`,
                        method: 'DELETE'
                    }).then(() => {
                        this.visible = false;
                        this.$set(this.special, this.select, null);
                    }).finally(() => {
                        this.loading = false;
                    });
                }
            },
            handleSelect(e, day) {
                if(!day.inMonth) return;
                this.select = day.dateStr;
            }
        }
    }
</script>

<style scoped lang="less">
  .buddha-time {
    position: relative;
    line-height: 1.5;
    .detail-info-item {
      font-size: 14px;
    }
  }
  .buddha-time-tip {
    color: @text-color-secondary;
  }
  .buddha-time-set {
    width: 600px;
    margin-left: 10px;
    padding: 0 20px;
    border-left: var(--border);
  }
  .time-piece-title {
    margin-bottom: 0;
    color: @orange-6;
    .txt-btn {
      margin-left: 8px;
    }
  }
  .time-select-piece {
    position: relative;
  }
  .time-set-reset {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .time-set-item {
    margin-top: 10px;
  }
  .time-set-label {
    flex-shrink: 0;
    margin-right: 10px;
    line-height: 33px;
  }
  .time-set-input {
    display: flex;
    align-items: center;
  }
  .time-set-remove {
    margin-left: 8px;
    width: 18px;
    height: 18px;
    font-size: 18px;
    color: @text-color-secondary;
    cursor: pointer;
  }
  .buddha-time-day {
    padding: 6px 0;
    user-select: none;
    &.time-day-select {
      background-color: @primary-color;
      color: @white;
    }
  }
  .time-day-special {
    background-color: @highlight-color;
    color: @white;
  }
  .time-day-set {
    margin-top: 10px;
  }
  .time-day-text {
    font-size: 16px;
    font-weight: 600;
  }
</style>
