<template>
    <div>
        <div style="font-size: 16px;font-weight: 600;color: #000">{{tablet.num}}号牌位</div>
        <div style="position: relative;margin-top: 10px;" :style="{width: width ? `${width}px` : 0, height: w ? h / w * width + 'px' : 0}">
            <div style="position:absolute;top:0;left:0;transform-origin: 0 0;font-weight:500;line-height:1;color:#000;font-family: Source Han Sans CN" :style="style">
                <img :src="img" style="position:absolute;top:0;left:0;width:100%;height:100%;" v-if="img">
                <div style="position:absolute;top:550px;left:0;right:0;margin:0 auto;padding-top:18px;z-index:1;width:100px;height:360px;font-size:32px;">
                    <div style="margin-bottom:0.5em;text-align:center;" v-if="text.title">{{text.title}}</div>
                    <div style="display:flex;flex-direction:row-reverse;justify-content:center;"
                         v-if="text.person && text.person.length > 0">
                        <div style="width:1em;" :style="i == 0 ? {} : {marginRight: '24px'}" v-for="(p, i) in text.person" :key="i">
                            <div :style="{height: t ? '1em' : '0.5em'}" v-for="(t, j) in p" :key="j">{{t}}</div>
                        </div>
                    </div>
                </div>
                <div style="display:flex;justify-content:center;position:absolute;left:90px;bottom:215px;width:100px;">
                    <div style="display:flex;flex-direction:column;"
                         :style="text.posterityStyle"
                         v-if="text.posterity && text.posterity.length > 0">
                        <div style="width:1em;">
                            <div>阳</div>
                            <div style="margin-top:10px;">上</div>
                        </div>
                        <div style="display:flex;flex-direction:row-reverse;justify-content:flex-end;margin-top:0.5em;" v-for="(piece, j) in text.posterity" :key="j">
                            <div style="width:1em;" :style="i == 0 ? {} : {marginRight: '0.5em'}" v-for="(p, i) in piece" :key="i">
                                <div :style="{height: t ? '1em' : '0.5em'}" v-for="(t, j) in p" :key="j">{{t}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {hasRelation, buddhismRelationList} from "../common/buddha/buddhism";

    export default {
        name: "Tablet",
        props: {
            tablet: Object,
            temp: Object,
            width: Number
        },
        data() {
            return {
                w: 464,
                h: 1334
            }
        },
        computed: {
            scale() {
                let width = this.width;
                if(width) {
                    return width / this.w;
                } else {
                    return null;
                }
            },
            style(){
                let scale = this.scale;
                let res = {width: `${this.w}px`, height: `${this.h}px`}
                if(scale) {
                    res.transform = `scale(${scale})`;
                }
                return res;
            },
            relation() {
                let tablet = this.tablet;
                let temp = this.temp;
                if(tablet && temp) {
                    return hasRelation({cat: temp.cat, type: tablet.type});
                } else {
                    return null;
                }
            },
            img() {
                let relation = this.relation;
                if(typeof relation === 'boolean') {
                    return require(relation ? "../assets/buddha/die-tablet.png" : "../assets/buddha/live-tablet.png");
                } else {
                    return null;
                }
            },
            text() {
                let res = {person: [], posterity: []};
                let hr = this.relation;
                if(typeof hr === 'boolean') {
                    let tablet = this.tablet;
                    let personList = tablet.personList;
                    if(hr) {
                        let relation = tablet.relation;
                        let posterityList = [...(tablet.posterityList || [])];
                        //兼容小程序提交的牌位信息关系直接传名称的情况
                        let type = buddhismRelationList.find(r => r.key == relation || r.title == relation);
                        const rk = type ? type.key : 0;
                        if(rk < 5) {
                            let rt = type.title;
                            // 自定义牌位
                            if(rk == 0) {
                                res.person = personList.map(item => {
                                    return item.name.split("");
                                });
                            } else {
                                // 氏门中历代宗亲
                                if(rk == 1) {
                                    rt = `${personList[0].name}${rt}`;
                                }
                                res.person = [rt.split("")]
                            }
                        } else {
                            let max = this.getMaxLength(personList, 'relation');
                            // 多个牌位会提出关系的第一个字
                            if(personList.length > 1) {
                                max -= 1;
                            }
                            res.person = personList.map(item => {
                                let ir = item.relation.split("");
                                // 多个牌位提出关系的第一个字
                                if(personList.length > 1) {
                                    res.title = ir.splice(0, 1).join("");
                                }
                                ir = ir.concat(new Array(max - ir.length).fill(" "));
                                return [...ir, "", ...item.name.split("")];
                            });
                        }
                        let prev = type.prev ? [...type.prev.split(""), ""] : [];
                        let posterity = [];
                        const posterityStyle = {};
                        if(posterityList.length <= 2) {
                            posterityStyle.fontSize = '30px';
                        } else if(posterityList.length > 3) {
                            posterityStyle.fontSize = '20px';
                        } else {
                            posterityStyle.fontSize = '24px';
                        }
                        // 超过3个阳上 分两排展示
                        do {
                            posterity.push(posterityList.splice(0, 3))
                        } while (posterityList.length > 0);
                        posterity = posterity.map(piece => {
                            let rm = this.getMaxLength(piece, 'relation');
                            let nm = this.getMaxLength(piece, 'name');
                            return piece.map(item => {
                                let rl = [];
                                if(item.relation) {
                                    rl = item.relation.split("");
                                    rl = rl.concat(new Array(rm - rl.length).fill(" "))
                                }
                                let rn = item.name.split("");
                                rn = rn.concat(new Array(nm - rn.length).fill(" "));
                                if(type.prev) {
                                    rn.push("",  "叩", "拜");
                                }
                                return [...prev, ...rl, rk < 5 ? "" : "：", ...rn];
                            });
                        })
                        res.posterity = posterity;
                        res.posterityStyle = posterityStyle;
                    } else {
                        if(tablet.user_type == 1) {
                            res.person = personList.map(item => {
                                return item.name.split("");
                            });
                        } else {
                            let person = [];
                            // 兼容 小程序提交牌位信息的公司团体名称放在personList里
                            let name = tablet.name || (tablet.personList && tablet.personList.length > 0  ? tablet.personList[0].name : '')
                            let list = name.split("");
                            let max = 6;
                            let l = list.length;
                            let num = Math.ceil(l / max);
                            for(let i = 0; i < num; i++) {
                                person.push(list.slice(i * max, i * max + max));
                            }
                            res.person = person;
                        }
                    }
                }
                return res;
            }
        },
        methods: {
            getMaxLength(list, key) {
                let max = 0;
                list.forEach(item => {
                    let str = item[key];
                    if(str && str.length > max) {
                        max = str.length;
                    }
                });
                return max;
            }
        }
    }
</script>

<style>

</style>
