<template>
  <div class="reserve-time">
    <table>
      <thead>
      <tr>
        <th>时间段</th>
        <th>最大预约堂数</th>
        <th v-if="edit">操作</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(t, i) in list" :key="i">
        <template v-if="index == i">
          <td><a-select v-model="form.rangeId" :options="rangeOptions" /></td>
          <td><a-checkbox v-model="form.limitless">不限</a-checkbox><a-input-number :min="1" v-model="form.limit" v-if="!form.limitless"></a-input-number></td>
          <td v-if="edit">
            <span class="txt-btn grey" @click="cancelTime()">取消</span>
            <span class="txt-btn" style="margin-left: 6px" @click="saveTime(index)">保存</span>
          </td>
        </template>
        <template v-else>
          <td>{{getRangeName(t.rangeId)}}</td>
          <td>{{t.limit == -1 ? '不限' : t.limit}}</td>
          <td v-if="edit">
            <span class="txt-btn" @click="editTime(i)">修改</span>
            <span class="txt-btn danger" style="margin-left: 6px" @click="removeTime(i)">删除</span>
          </td>
        </template>
      </tr>
      <tr v-if="edit">
        <template v-if="index == list.length">
          <td><a-select v-model="form.rangeId" :options="rangeOptions" /></td>
          <td><a-checkbox v-model="form.limitless">不限</a-checkbox><a-input-number :min="1" v-model="form.limit" v-if="!form.limitless"></a-input-number></td>
          <td v-if="edit">
            <span class="txt-btn grey" @click="cancelTime()">取消</span>
            <span class="txt-btn" style="margin-left: 6px" @click="saveTime(index)">保存</span>
          </td>
        </template>
        <td colspan="3" v-else>
          <span class="txt-btn" @click="addTime">添加时间段</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
    import {clone} from "../../common/js/tool";
    export default {
        name: "reserve-time",
        model: {
            event: "change"
        },
        props: {
            value: Array,
            edit: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                list: [],
                form: {},
                index: null
            }
        },
        computed: {
            // 最新的时间段
            timeRange() {
                return this.$store.getters.timeRange;
            },
            // 前一次预约设置时保存的时间段快照
            rangeList() {
                return this.$parent && this.$parent.normal ? this.$parent.normal.timeRangeList : null;
            },
            rangeOptions() {
                return this.timeRange ? this.timeRange.map(item => {
                    return {
                        key: item.id,
                        title: item.name
                    }
                }) : [];
            }
        },
        watch: {
            value() {
                this.setList();
            }
        },
        created() {
            this.setList();
        },
        methods: {
            getRangeName(id) {
                let r;
                /**
                 * 编辑状态先从最新的时间段列表中查找，如果找不到则从快照中查找
                 * 非编辑状态直接从快照中查找
                 */
                if(this.edit) {
                    r = (this.timeRange || []).find(item => item.id == id);
                    if(!r) {
                        r = (this.rangeList || []).find(item => item.id == id);
                    }
                } else {
                    r = (this.rangeList || []).find(item => item.id == id);
                }
                return r ? r.name : '';
            },
            setList() {
                let value = this.value;
                if(value) {
                    this.list = clone(value);
                } else {
                    this.list = [];
                }
            },
            saveTime(index) {
                let form = this.form;
                if(!form.rangeId) {
                    this.$message.warning("请选择时间段");
                    return;
                }
                if(!form.limitless && !form.limit) {
                    this.$message.warning("请输入最大预约数");
                    return;
                }
                let item = {rangeId: form.rangeId, limit: form.limit};
                if(form.limitless) {
                    item.limit = -1;
                }
                if(index == this.list.length) {
                    this.list.push(item);
                } else {
                    this.$set(this.list, index, item);
                }
                this.index = null;
                this.dispatchChange();
            },
            cancelTime() {
                this.index = null;
                this.form = {};
            },
            addTime() {
                this.form = {range: ['06:00', '08:00'], limit: 1};
                this.index = this.list.length;
            },
            removeTime(index) {
                this.list.splice(index, 1);
                this.dispatchChange();
            },
            editTime(i) {
                let form = clone(this.list[i]);
                if(form.limit == -1) {
                    form.limitless = true;
                }
                this.form = form;
                this.index = i;
            },
            dispatchChange() {
                let list = this.list;
                this.$emit("change", list.length > 0 ? list.map(item => {return {rangeId: item.rangeId, limit: item.limit}}) : null)
            }
        }
    }
</script>

<style lang="less">
  .reserve-time {
    flex: 1;
    .time-set-input {
      &:not(:first-of-type) {
        margin-top: 6px;
      }
    }
    table {
      width: 100%;
      line-height: 1.5;
      text-align: center;
    }
    tr,td,th {
      border: var(--border);
      padding: 6px 10px;
    }
    td {
      &:first-of-type {
        width: 40%;
      }
      &:last-of-type {
        width: 16%;
      }
    }
  }
</style>
